<template>
  <BaseCitypolyDetailsPannel
    v-if="!poiIsDisable"
    :item="poi"
    :close-btn="routeName === 'placeView'"
    @close="onClosePoiPreview"
  >
    <template #header>
      <div class="header-detail-pannel">
        <h3
          v-if="shoudlDisplaypropertyTitle"
        >
          {{ $t(pfx + "propertyTitle") }}
        </h3>
        <h2>{{ title() }}</h2>
        <div
          v-if="!shoudlDisplaypropertyTitle"
          class="header-price"
        >
          <h3>
            {{ poiCost }}
          </h3>
          <img
            class="header-cityflouz-img"
            :src="`/themes/${appConfig.platformType}/images/cityflouz_logo.png`"
            alt="cityflouz img"
          >
        </div>
      </div>
    </template>
    <template #default>
      <content-section
        v-if="shouldDisplayBaseContent"
        :title="$t(pfx + 'descriptionTitle')"
        :content="nl2br(description(poi, lang))"
      />
      <content-section
        v-if="shoudlDisplaypropertyTitle"
        :title="$t(pfx + 'owner')"
        :content="currentTeam.name + $t(pfx + 'ownerDescription')"
      />
      <SimpleQuestionForm
        v-if="currentQuestion"
        :question="currentQuestion"
        @send-answer="checkMortgageRequirement"
      />
      <content-section
        v-if="displayPayRent"
        :title="$t(pfx + 'privatePropertyTitle')"
        :content="$t(pfx + 'privatePropertyContent', {cost: poiRentValue, img: cityflouzImg})"
      />
      <content-section
        v-if="displayMortgagedContent"
        :title="$t(pfx + 'privatePropertyTitle')"
        :content="$t(pfx + 'privatePropertyMortgagedContent')"
      />
    </template>
    <template #footer>
      <div v-if="!currentQuestion && teamAction">
        <button
          v-if="teamAction.type === TEAM_ACTIONS.BUY_CHALLENGE"
          class="notify__button is-active js-notify-toggler btn-city-poi"
          @click="redirectWithAction(TEAM_ACTIONS.REJECT)"
        >
          {{ $t(pfx + "dontBuy") }}
        </button>
        <PanelBtn
          v-if="teamAction"
          :title="btnText()"
          @click="redirectWithAction(teamAction.type)"
        />
      </div>
      <ErrorPopup
        v-if="shouldDisplayErrorAlert"
        :title="$t(pfx + 'errorTitlte')"
        :message="$t(pfx + 'errorMessage')"
        :button-text="$t(pfx + 'errorBtn')"
        :open="true"
        @close="shouldDisplayErrorAlert = false"
      />
    </template>
  </BaseCitypolyDetailsPannel>
</template>

<script setup>
import { onMounted, reactive, ref, computed, watch } from 'vue';
import BaseCitypolyDetailsPannel from '../citypoly/BaseCitypolyDetailsPanel.vue';
import { poiTitle, poiDescription } from '../../translationTools/poi';
import ContentSection from '../map/PanelModuleDescription.vue';
import {
  debugAction,
  fetchPropertyTitle,
  getPoiChallenge,
  postAnswerBuyChallenge,
  putMortagingPoi,
} from '../../api/citypoly';
import quizzApi from '../../api/quizz';
import * as Sentry from '@sentry/vue';
import PanelBtn from '../map/PanelBtn.vue';
import { POI_STATUS, TEAM_ACTIONS } from '../../constants';
import SimpleQuestionForm from './SimpleQuestionForm.vue';
import { badNewsConfetti, basicConfetti } from '../../tools/CanvasConfetti';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';
import { useConfigStore } from '../../pinia/configuration';
import ErrorPopup from '../ErrorPopup.vue';
import { useRoute, useRouter } from 'vue-router';
import { useGameStore } from '../../pinia/game';
import { usePoiStore } from '../../pinia/poi';
import { useErrorHandler } from '../../composables/handleErrors';
const gameStore = useGameStore();
const configStore = useConfigStore();
const poiStore = usePoiStore();
const emit = defineEmits(['close', 'rollDice', 'reject']);
const pfx = 'components.citypoly.BaseDetailsPannel.';
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { logError } = useErrorHandler();

const props = defineProps({
  poi: {
    type: Object,
    required: true,
  },
});

const routeName = computed(() => route.name);
const appConfig = computed(() => configStore.appConfig);

const token = computed(() => gameStore.currentToken);
const currentTeam = computed(() => gameStore.currentTeam);
const teamAction = computed(() => gameStore.currentTeam?.currentAction);
const currentQuestion = ref(null);
const displayPayRent = ref(false);
const poiStatus = ref(null);
const poisToMortgage = ref([]);
const unmortgagedPois = ref([]);
const displayMortgagedContent = ref(false);
const poiIsDisable = ref(false);
const poiCost = ref(0);
const shouldDisplayErrorAlert = ref(false);

const cityflouzImg = `<img src="/themes/${appConfig.value.platformType}/images/cityflouz_logo.png" style="width:27px;height:27px; margin-left:-5px; margin-top:-3px" />`;

const coords = reactive({
  latitude: poiStore.currentLocation.latitude,
  longitude: poiStore.currentLocation.longitude,
});

const lang = computed(() => configStore.appConfig.lang);
const shouldDisplayBaseContent = computed(() => {
  if (
    poiStatus.value === POI_STATUS.AVAILABLE &&
    teamAction.value.type === TEAM_ACTIONS.BUY_CHALLENGE &&
    !currentQuestion.value
  ) {
    return true;
  }
  return false;
});

const shoudlDisplaypropertyTitle = computed(() => {

  if (poiStatus.value === POI_STATUS.OWNED_BY_SELF && teamAction.value.type === TEAM_ACTIONS.ROLL) {
    return true;
  }
  return false;
});

const poiRentValue = computed(() => poiCost.value * 0.15);


const fetchCurrentTeamCitypoly = async () => {
  await gameStore.fetchCurrentTeam();
};

const btnText = () => {
  switch (teamAction.value.type) {
    case TEAM_ACTIONS.ROLL:
      return t(pfx + 'goToDice');
    case TEAM_ACTIONS.BUY_CHALLENGE:
      return t(pfx + 'buyChallenge');
    case TEAM_ACTIONS.PAY:
      return t(pfx + 'payRent');
    default:
      return ''

  }
};

const title = () => {
  return poiTitle(props.poi, lang.value);
};

const onClosePoiPreview = () => {
  router.go(-1)
}

const description = () => {
  return poiDescription(props.poi, lang.value);
};

const nl2br = (content) => {
  return content.replace(/(\\r)*\\n/g, '<br/>');
};

const checkin = async () => {
  try {
    const res = await gameStore.doCitypolyCheckin({poiId: props.poi.uuid, coords });
    if (res) {
      poiStatus.value = res.poiStatus;
      poiCost.value = res.poiCost;
    }
    return;
  } catch (error) {
    logError(error, 'PanelCitypolyPoi.checkin');
  }
};

const poiAction = async () => {
  if (
    teamAction.value.type === TEAM_ACTIONS.BUY &&
    poiStatus.value === POI_STATUS.BUYING_IN_PROGRESS
  ) {
    try {
      await gameStore.doCitypolyBuy(props.poi.uuid);
      basicConfetti();
      await gameStore.getPropertyTitles();
      return;
    } catch (error) {
      logError(error, 'PanelCitypolyPoi.poiAction');
    }
  }
  if (teamAction.value.type === TEAM_ACTIONS.PAY && poiStatus.value === POI_STATUS.OWNED_BY_OTHER) {
    badNewsConfetti();
    displayPayRent.value = true;
    return;
  }
  if (poiStatus.value === POI_STATUS.MORTGAGED) {
    displayMortgagedContent.value = true;
  }
};

const getChallenge = async () => {
  try {
    const res = await getPoiChallenge(appConfig.value.gameApiBaseUrl, props.poi.uuid, token.value);
    // If there are no more questions for this place, we will redirect you to roll dice.
    if (res && res.length === 0) {
      const result = await Swal.fire({
        title: t(pfx + 'emptyChallenge.title'),
        text: t(pfx + 'emptyChallenge.text'),
        showDenyButton: false,
        confirmButtonText: t(pfx + 'emptyChallenge.confirmBtn'),
      });
      if (result.isConfirmed) {
        const actionRequest = {
          actionType: 'roll_dice',
          targetId: props.poi.uuid,
        };
        const res = await debugAction(appConfig.value.gameApiBaseUrl, actionRequest, token.value);
        if (res) {
          emit('rollDice');
          return;
        }
      }
    } else {
      currentQuestion.value = res[0];
    }
  } catch (error) {
    logError(error, 'PanelCitypolyPoi.getChallenge');
  }
};

const mortagingPoi = async (poiId) => {
  try {
    await putMortagingPoi(appConfig.value.gameApiBaseUrl, poiId, token.value);
  } catch (error) {
    logError(error, 'PanelCitypolyPoi.mortagingPoi');
  }
};

const sendAnswer = async (answer, mortgageIsRequired, pictureData) => {
  const answerData = answer;
  answerData.partyId = currentTeam.value.partyId;
  answerData.teamId = currentTeam.value.id;
  try {
    if (pictureData) {
      const res = await quizzApi.postImage(
        appConfig.value.gameApiBaseUrl,
        pictureData,
        currentTeam.value.id,
        token.value
      );
      answerData.pictureUrl = res.url;
      if (!answer.pictureUrl) {
        shouldDisplayErrorAlert.value = true;
        return;
      }
    }
    const res = await postAnswerBuyChallenge(
      appConfig.value.gameApiBaseUrl,
      props.poi.uuid,
      answerData,
      token.value
    );
    if (res && res.type === TEAM_ACTIONS.BUY) {
      if (mortgageIsRequired) {
        await Promise.all(
          poisToMortgage.value.map(async (poi) => {
            await mortagingPoi(poi.uuid);
          }),
          fetchCurrentTeamCitypoly()
        );
      }
      await gameStore.doCitypolyBuy(props.poi.uuid);
      currentQuestion.value = null;
      basicConfetti();
      poiStatus.value = POI_STATUS.OWNED_BY_SELF;
      await gameStore.getPropertyTitles();
      return;
    }
    if (res && res.type === TEAM_ACTIONS.ROLL) {
      currentQuestion.value = null;
      teamAction.value = {
        type: TEAM_ACTIONS.ROLL,
      };
      Swal.fire({
        title: t(pfx + 'wrongAnswerTitle'),
        text: t(pfx + 'wrongAnswerContent'),
      });
      redirectWithAction(TEAM_ACTIONS.ROLL);
    }
  } catch (error) {
    shouldDisplayErrorAlert.value = true;
    logError(error, 'PanelCitypolyPoi.sendAnswer');
  }
};

const availableMortgageValue = async () => {
  try {
    const propertyTitlesList = await gameStore.getPropertyTitles();
    let pois = Array.isArray(poiStore.activePois) ? poiStore.activePois : [];

    unmortgagedPois.value = pois.filter((poi) => {
      const propertyTitle = propertyTitlesList.find(
        (propertyTitle) => propertyTitle.poiId === poi.uuid
      );
      return propertyTitle && propertyTitle.isMortgaged === false;
    });
    const unmortgagedPoisValue = unmortgagedPois.value.reduce(
      (acc, poi) => acc + (poi.points || 0),
      0
    );
    const mortgageableValue = unmortgagedPoisValue * 0.5;
    const totalValue = mortgageableValue + currentTeam.value.score;

    if (totalValue >= props.poi.points) {
      poiIsDisable.value = false;
      return;
    } else {
      Swal.fire({
        title: t(pfx + 'ruinedAlert.title'),
        text: t(pfx + 'ruinedAlert.text'),
      });
      poiIsDisable.value = true;
    }
  } catch (error) {
    logError(error, 'PanelCitypolyPoi.availableMortgageValue');
  }
};

const checkMortgageRequirement = async (answer, pictureData) => {
  if (currentTeam.value.score >= props.poi.points) {
    await sendAnswer(answer, false, pictureData);
    return;
  } else {
    const currentScore = currentTeam.value.score;
    const poiCost = props.poi.points;
    const requiredAmount = poiCost - currentScore;

    const sortedPois = [...unmortgagedPois.value].sort((a, b) => b.points / 2 - a.points / 2);

    let accumulatedMortgagePoints = 0;
    poisToMortgage.value = [];

    sortedPois.forEach((poi) => {
      const mortgagePoints = poi.points / 2;
      if (accumulatedMortgagePoints >= requiredAmount) return;
      poisToMortgage.value.push(poi);
      accumulatedMortgagePoints += mortgagePoints;
    });

    const totalMortgagePoints = accumulatedMortgagePoints + currentScore;
    if (totalMortgagePoints >= poiCost) {
      const result = await Swal.fire({
        title: t(pfx + 'mortgageAlert.title'),
        text: t(`${pfx}mortgageAlert.text`, { count: poisToMortgage.value.length }),
        showDenyButton: true,
        confirmButtonText: t(pfx + 'mortgageAlert.confirmBtn'),
        denyButtonText: t(pfx + 'mortgageAlert.denyBtn'),
      });
      if (result.isConfirmed) {
        await sendAnswer(answer, true, pictureData);
      }
    }
  }
};

const redirectWithAction = async (action) => {
  switch (action) {
    case TEAM_ACTIONS.BUY_CHALLENGE:
      await getChallenge();
      break;
    case TEAM_ACTIONS.REJECT:
      emit('reject');
      break;
    case TEAM_ACTIONS.ROLL:
      emit('rollDice');
      break;
    case TEAM_ACTIONS.PAY:
      try {
        const res = await gameStore.doCitypolyPayRent(props.poi.uuid);
        displayPayRent.value = false;
        emit('rollDice');
        return;
      } catch (error) {
        logError(error, 'PanelCitypolyPoi.redirectWithAction');
      }
      break;
    default:
      break;
  }
};

  watch(
    () => currentTeam.value.score,
    async () => {
      if (poiStatus.value === POI_STATUS.OWNED_BY_SELF) {
        return;
      } else {
        await availableMortgageValue();
      }
    },
    { immediate: true }
  );

onMounted(async () => {
  if(route.name === 'placeView') {
    return;
  }
  await checkin();
  await poiAction();
});
</script>

<style lang="scss" scoped>
.header-detail-pannel {
  display: flex;
  flex-direction: column;
  & h3 {
    margin-bottom: 0.4rem;
    color: #000;
  }
}

.btn-city-poi {
  border: none;
  background-color: #d24d57;
}

.header-price {
  display: flex;
  align-items: center;
  justify-content: center;
  & h3 {
    margin-right: -5px;
  }
}

.header-cityflouz-img {
  width: 40px;
  height: 40px;
  margin-top: -5px;
}
</style>
