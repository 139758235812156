<template>
  <div class="button-group-fixed">
    <btn-menu
      :main-menu-open="mainMenuOpen"
      @togglemenu="$emit('togglemenu')"
    />
    <btn-round
      :icon="gpsButtonIcon"
      @clicked="$emit('resetmap')"
    />
    <btn-balance
      v-if="props.enableLogin"
      :balance="userBalance"
    />
    <btn-round
      v-if="enableHelpBtn"
      :icon="`/themes/${theme}/images/icons/ico-info.svg`"
      :width="100"
      :height="100"
      @clicked="$emit('closeRules')"
    />
    <a
      class="btn-round messages-container"
      :class="{ 'breathing-animation': chatStore && hasNewMessage }"
      @click="$emit('displayChat')"
    >
      <btn-round
        :icon="`/themes/${theme}/images/icons/ico-chat.svg`"
        :width="30"
        :height="30"
      />
      <div
        v-if="chatStore && hasNewMessage"
        class="messages-puce"
      />
    </a>
  </div>
</template>

<script setup>
import BtnMenu from './BtnMenu.vue';
import BtnRound from './BtnRound.vue';
import BtnBalance from './BtnBalance.vue';
import { useChatStore } from '../pinia/chat';
import { storeToRefs } from 'pinia';

const props = defineProps({
  userBalance: {
    type: Number,
    required: true,
  },
  gpsButtonIcon: {
    type: String,
    required: true,
  },
  enableLogin: {
    type: Boolean,
    required: true,
  },
  mainMenuOpen: {
    type: Boolean,
    required: true,
  },
  theme: {
    type: String,
    required: false,
    default: 'dotmap',
  },
  enableHelpBtn: {
    type: Boolean,
    required: true,
  },
  enableChatBtn: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['togglemenu', 'resetmap', 'closeRules', 'displayChat']);

const chatStore = useChatStore();
const { hasNewMessage } = storeToRefs(chatStore);

</script>

<style lang="scss" scoped>
.messages-container {
  margin-top: .5rem;
  position: relative;
  cursor: pointer;
}
.messages-puce {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  border: white 2px solid;
  position: absolute;
  top: -5px;
  right: -5px;
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.breathing-animation {
  animation: breathing 1s ease-in-out infinite;
}
</style>
