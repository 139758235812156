import { ref, computed } from 'vue';
import { useConfigStore } from '../pinia/configuration';
import { useGameStore } from '../pinia/game';
import { usePoiStore } from '../pinia/poi';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/vue';
import { useErrorHandler } from './handleErrors';

export function UseCheckinControl(poi) {
  const CODE_CONFLICT = 409;
  const CODE_INTERNAL_SERVER_ERROR = '500';
  const PARTY_ENDED = 'ended';

  const configStore = useConfigStore();
  const poiStore = usePoiStore();

  const { t } = useI18n();
  const checkinInProgress = ref(false);
  const currentPOI = ref(poi);
  const gameStore = useGameStore();

  const { logError } = useErrorHandler();


  const isWithinCheckinDistance = computed(() => {
    if (!currentPOI.value) {
      return false;
    }
    if (!currentPOI.value.distance) {
      return false;
    }
    if (currentPOI.value.checkinType === 'gps') {
      return currentPOI.value.distance <= configStore.appConfig.baseCheckinDistance;
    }
    if (currentPOI.value.checkinType === 'qrcode') {
      const qrcodeCheckinDistance = configStore.appConfig.baseCheckinDistance * 3;
      return currentPOI.value.distance <= qrcodeCheckinDistance;
    }
    return false;
  });


  const checkinDone = computed(() => {
    if (!gameStore.currentTeam) {
      return false;
    }
    const checkedInPois = Object.keys(gameStore.checkinsByPOI);
    return checkedInPois.includes(currentPOI.value.uuid);
});

const canCheckin = computed(() => {
  if (!gameStore.currentTeam.value) {
    return false;
  }
  if (!isWithinCheckinDistance.value) {
    return false;
  }
  if (checkinDone.value) {
    return false;
  }
  return true;
});

/**
 * surround with emit('checkinstart') adn emit('checkindone', { poiId: this.poi.uuid, score: this.poi.points }) if returns true. send 'checkincancelled' if returns false or throws an error
 * @returns bool
 */
const performCheckin = async () => {
  if (checkinInProgress.value === true) {
    return false;
  }
  checkinInProgress.value = true;
  const checkinName = `CHECKIN-${currentPOI.value.name}`;
  try {
    await gameStore.doCheckin({
      name: checkinName,
      poiId: currentPOI.value.uuid,
      longitude: poiStore.currentLocation.longitude,
      latitude: poiStore.currentLocation.latitude,
      points: currentPOI.value.points,
    });
    checkinInProgress.value = false;
    return true;
  } catch (error) {

    logError(error, 'checkinControl.performCheckin');

    if (error.response && error.response.status === CODE_CONFLICT) {
      errorAlert();
      checkinInProgress.value = false;
      return false;
    } else if (error.message.includes(CODE_INTERNAL_SERVER_ERROR)) {
      const party = await gameStore.getCurrentParty();
      if (party.status === PARTY_ENDED) {
        Swal.fire({
          title: `${t('components.PanelContainer.error.partyStateTitle')}`,
          text: `${t('components.PanelContainer.error.partyStateContent')}`,
          icon: 'warning',
        });
        checkinInProgress.value = false;
        return false;

      } else {
        errorAlert(error);
        checkinInProgress.value = false;
        return false;
      }
    } else {
      errorAlert(error);
      checkinInProgress.value = false;
      return false;
    }
  }
};

const errorAlert = (error) => {
  if (error) {
    Sentry.captureException(error);
  }
  return Swal.fire({
    title: `${t('components.PanelContainer.error.unknownTitle')}`,
    text: `${t('components.PanelContainer.error.unknownContent')}`,
    icon: 'error',
  });
};

  return { isWithinCheckinDistance, checkinDone, canCheckin, checkinInProgress, performCheckin };
};
