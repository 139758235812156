<template>
  <div class="place-indicator__container">
    <div class="place-indicator__icon"><img src="/images/icons/ico-routes.svg" height="60px" width="60px" alt="illustrative-icon"/></div>
    <div class="place-indicator__name">
      <p>Rendez-vous vers le lieu : </p>
      <h3>{{ poiName }}</h3>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { usePOIManager } from '../../composables/POIManager';


const props = defineProps({
  poi: {
    type: Object,
    required: true,
  }
});
const poiName = ref(null);
onMounted(() => {

  const { title } = usePOIManager(props.poi);
  poiName.value = title;
});


</script>
<style lang="scss" scoped>
.place-indicator__container {
  z-index: 11;
  color: white;
  position:absolute;
  bottom: 110px;
  left:5%;
  width:90%;
  height:100px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 20px;
  background: rgba(0,0,0,0.58);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  border-radius: 8px;
  backdrop-filter: blur(8px);
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
