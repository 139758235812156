// stores/websocket.js
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useWebSocket } from '@vueuse/core';
import { WS_ACTIONS } from '../constants';
import { useGameStore } from './game';
import { useChatStore } from './chat';
import { storeToRefs } from 'pinia';

export const useWebSocketStore = defineStore('websocket', () => {
  const status = ref('DISCONNECTED');
  const gameStore = useGameStore();
  const chatStore = useChatStore();

  // Get reactive refs
  const { chatMessages, hasNewMessage } = storeToRefs(chatStore);
  const { currentTeam } = storeToRefs(gameStore);

  let webSocket;
  let pingTimer;
  let timer = 15000;

  const startPingTimer = () => {
    pingTimer = setTimeout(() => {
      webSocket?.close();
      webSocket?.open();
    }, timer);
  };

  const connect = (wsUrl) => {
    webSocket = useWebSocket(wsUrl, {
      immediate: true,
      autoReconnect: {
        retries: Infinity,
        delay: 3000,
        onFailed() {
          console.log('WebSocket reconnection failed');
          status.value = 'DISCONNECTED';
        },
      },
      heartbeat: {
        message: 'ping',
        responseMessage: 'pong',
        interval: 10000,
        pongTimeout: 1000,
      },
      onConnected(ws) {
        console.log('WebSocket connected');
        status.value = 'CONNECTED';
        startPingTimer();
      },
      onDisconnected(ws, event) {
        console.log('WebSocket disconnected:', event);
        status.value = 'DISCONNECTED';
        clearTimeout(pingTimer);
      },
      onError(ws, evt) {
        console.error('WebSocket error: ', evt);
        status.value = 'ERROR';
      },
      onMessage(ws, evt) {
        if (evt.data === WS_ACTIONS.PING) {
          clearInterval(pingTimer);
          startPingTimer();
          return;
        }
        if (evt.data !== null && !evt.data.includes(WS_ACTIONS.PING)) {
          try {
            const wsData = JSON.parse(evt.data);
            const currentTeamId = currentTeam.value.id;
            // data of chat
            if (wsData && (currentTeamId === wsData.toUserId ||
              wsData.kind === WS_ACTIONS.BROADCAST ||
              currentTeamId === wsData.fromUserId
            )) {
              chatMessages.value.push(wsData);
              if (wsData.fromUserId === currentTeamId) {
                return
              }
              hasNewMessage.value = true;
            }
          } catch (error) {
            console.error('Error parsing message:', error);
          }
        }
      },
    });
    return webSocket;
  };

  return {
    status,
    connect,
  };
});
