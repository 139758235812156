<template>
  <a
    href="#"
    :class="buttonClasses"
    @click.prevent="emit('clicked');"
  >
    <img
      v-if="icon !== null"
      :src="icon"
      :width="width"
      :height="height"
      alt=""
    >
    <slot name="btn-extension" />
  </a>
</template>

<script setup>
import { computed } from 'vue';
const emit = defineEmits(['clicked']);
const props = defineProps({
  classes: {
    type: String,
    required: false,
    default: '',
  },
  width: {
    type: Number,
    required: false,
    default: 18,
  },
  height: {
    type: Number,
    required: false,
    default: 18,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
});
const buttonClasses = computed(() => `btn-round ${props.classes}`);
</script>
