<template>
  <div class="chat-container">
    <div class="chat-header">
      <h2>{{ $t(pfx + "title") }}</h2>
      <closeBtn
        :icon="`/themes/${theme}/images/icons/ico-close-transparent.svg`"
        :width="100"
        :height="100"
        :classes="'close'"
        @clicked="emit('close')"
      />
    </div>
    <div class="messages-wrapper">
      <div class="messages-scroll-area">
        <div
          id="messages-list"
          class="messages-list"
        >
          <div
            v-if="(!chatMessages || chatMessages.length === 0) && !isLoading"
            class="messages-empty"
          >
            {{ $t(pfx + 'emptyContent') }}
          </div>
          <div v-else>
            <MessageItem
              v-for="(message, idx) in chatMessages"
              :key="idx"
              ref="messageRefs"
              class="message-item"
              :class="{'message-item-team ': message.fromUserKind !== 'admin'}"
              :message="message"
              :is-admin="message.fromUserKind === 'admin'"
            />
          </div>
        </div>
      </div>
      <div
        class="message-input-area"
      >
        <input
          v-model="messageToSend"
          class="message-input"
          :disabled="isLoading"
          @keyup.enter="sendMessage"
        >
        <button
          class="send-button"
          :disabled="isLoading"
          aria-label="Send message"
          @click="sendMessage"
        >
          <img
            :src="`/themes/${theme}/images/icons/ico-paperplane-black.svg`"
            alt="Send message icon"
            class="send-icon"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick } from 'vue';
import { computed, onMounted, ref, watchEffect } from 'vue';
import MessageItem from './MessageItem.vue';
import closeBtn from '../BtnRound.vue';
import { postMessage } from '../../api/chat';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { useErrorHandler } from '../../composables/handleErrors';
import { useChatStore } from '../../pinia/chat';
import { storeToRefs } from 'pinia';

const appConfig = useConfigStore().appConfig;
const gameStore = useGameStore();
const chatStore = useChatStore();

const { chatMessages } = storeToRefs(chatStore);

const emit = defineEmits(['close']);
const pfx = 'components.chat.';
const { logError } = useErrorHandler();
const messageToSend = ref(null);
const messageRefs = ref([]);
const isLoading = ref(false);


const currentTeam = computed(() => gameStore.currentTeam);
const currentToken = computed(() => gameStore.currentToken);
const theme = computed(() => {
  return appConfig ? appConfig.cssTheme : 'dotmap';
});

const sendMessage = async () => {
  isLoading.value = true;
  const message = {
    partyId: currentTeam.value.partyId,
    fromUserId: currentTeam.value.id,
    toUserKind: 'admin',
    fromUserName: currentTeam.value.name,
    fromUserKind: 'team',
    content: messageToSend.value,
  };
  try {
    messageToSend.value = null;
    await postMessage(appConfig.gameApiBaseUrl, message, currentToken.value);
    messageToSend.value = null;
  } catch (error) {
    logError(error, 'ChatComponent.sendMessage');
  }
  isLoading.value = false;
};

watchEffect(() => {
  if (!chatMessages.value) {
    return;
  }
  if (chatMessages.value.length > 0) {
    nextTick(() => {
      const myElement = document.getElementById('messages-list');
      setTimeout(() => {
        myElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }, 200)
    });
  }
});
</script>

<style lang="scss" scoped>
.chat-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: black;
  z-index: 100;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  & h2 {
    color: #1a1a1a;
    margin-top: 0.5rem;
  }
}

.back-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.back-button:hover {
  background-color: #0056b3;
}

.messages-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.messages-scroll-area {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #f9f9f9;
}

.messages-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 60px;
}

.message-item {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #e6eef6;
  width: 70%;
}
.message-item-team {
  margin-left: auto;
  background-color: #efefef;
}

.message-input-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.message-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.message-input:focus {
  outline: none;
  border-color: #007bff;
}

.send-button {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.send-icon {
  width: 24px;
  height: 24px;
}
</style>
