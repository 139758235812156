<template>
  <div
    v-if="clues.length !== 0"
    class="clues-container"
  >
    <h3 class="clue-header">
      {{ $t("components.CluesQuestion.title") }}
    </h3>
    <div class="clues-btn">
      <button
        v-for="(clue, idx) in clues"
        :key="idx"
        class="clue"
        :class="bgColorClue(clue.unlocked)"
        type="button"
        @click="alertClue(clue.unlocked, clue.clue)"
      >
        {{ $t("components.CluesQuestion.clue") + " " + (idx + 1) }}
        <lock v-if="clue.unlocked === false" />
        <lock-open-variant v-if="clue.unlocked === true" />
      </button>
    </div>
  </div>
</template>
<script setup>

import Swal from 'sweetalert2';
import Lock from 'vue-material-design-icons/Lock.vue';
import LockOpenVariant from 'vue-material-design-icons/LockOpenVariant.vue';
import { getClues, buyClue } from '../../api/clues';
import toast from '../../tools/toastAlert';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { ref, computed, onMounted } from 'vue';
import { useErrorHandler } from '../../composables/handleErrors';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const  configStore = useConfigStore();
const gameStore = useGameStore();
const { logError } = useErrorHandler();

const FORBIDEN_STATUS = '403';
const props = defineProps({
  questionId: {
    type: String,
    required: true,
  },
});

const clues = ref([]);

const appConfig = computed(() => configStore.appConfig);
const teamId = computed(() => gameStore.currentTeam.id);
const token = computed(() => gameStore.currentToken);

onMounted(async () => {
  await loadClues();
});

const loadClues = async() => {
  clues.value = [];
  try {
    const res = await getClues(
      appConfig.value.gameApiBaseUrl,
      teamId.value,
      props.questionId,
      token.value,
    );
    res.sort((a, b) => a.clue.cost - b.clue.cost);
    clues.value = res;
  } catch (error) {
    logError(error, 'CluesQuestion.loadClues');
    toast.fire({ icon: 'error', title: t('components.CluesQuestion.errorGetClues') });
  }
};

const alertClue = (clueStatus, clue) => {
  if (clueStatus === true) {
  Swal.fire({
    title: t('components.CluesQuestion.clueTitle'),
    text: `${clue.content}`,
  });
  } else {
    Swal.fire({
      title: t('components.CluesQuestion.buyClueTitle'),
      text: t('components.CluesQuestion.labelCost', { cost: clue.cost }),
      showDenyButton: true,
      confirmButtonText: t('components.CluesQuestion.accept'),
      denyButtonText: t('components.CluesQuestion.decline'),
    }).then((result) => {
      if (result.isConfirmed) {
        doBuyClue(clue);
      }
    });
  }
};

const doBuyClue = async (clue) => {
  const requestedClue = {
    teamId: teamId.value,
    clueId: clue.id,
  };
  try {
    await buyClue(appConfig.value.gameApiBaseUrl, requestedClue, token.value);
    Swal.fire({
      title: t('components.CluesQuestion.clueTitle'),
      text: `${clue.content}`,
    }).then(() => {
      getClues();
    });
  } catch (error) {
    if (error.message.includes(FORBIDEN_STATUS)) {
      Swal.fire({
        title: t('components.CluesQuestion.labelBlockedClue'),
        text: t('components.CluesQuestion.labelInsufficientFunds'),
      });
    } else {
      logError(error, 'components.CluesQuestion.doBuyClue');
      toast.fire({
        icon: 'error',
        title: t('components.CluesQuestion.errorBuyingClue'),
      });
    }
  }
};

const bgColorClue = (clueStatus) => {
  if (clueStatus === true) {
    return 'clue-btn-unlocked';
  }
  return 'clue-btn-locked';
};
</script>

<style lang="scss" scoped>
.clues-container {
  margin: 1rem auto;
  padding-bottom: 0.5rem;
  background-color: #f4f4f4;
  border: solid 1px #f2f2f2;
  border-radius: 5px;
}
.clue-header {
  border-radius: 5px;
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-align: center;
  color: #da004b;
}
.clues-btn {
  display: flex;
  justify-content: space-evenly;
}
.clue {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 28%;
  margin: 0.4rem;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  & span {
    // pour aligner l'icon avec le text
    height: .7rem;
  }
}
.clue-btn-unlocked {
  background-color: #8f96a3;
  color: white;
}
.clue-btn-locked {
  background-color: #040404;
  color: white;
}
</style>
