<template>
  <div>
    <div id="usermarker" />
    <div
      id="map"
      class="section__background"
    />
  </div>
</template>

<script setup>
/**
 * Component in charge of the main map display.
 * This component is not allowed to talk to the state !
 */
import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import Swal from 'sweetalert2';

import * as Sentry from '@sentry/vue';

import * as k from '../constants';
import api from '../api/fetch';
import { useConfigStore } from '../pinia/configuration';
import { useGameStore } from '../pinia/game';
import { usePoiStore } from '../pinia/poi';
import { computed, ref, watch, onBeforeMount, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useErrorHandler } from '../composables/handleErrors';
import { storeToRefs } from 'pinia';
const { t }  = useI18n();
const MAP_EASE_DELAY = 250;
const DEFAULT_ZOOM = 16;
/**
 * options object for mapbox's fitBounds that prevents marker from being behind the UI controls
 */
const mapBoundsOptions = {
  padding: {
    top: 150, bottom: 100, left: 50, right: 100,
  },
};
const emit = defineEmits(['maploaded']);
const props = defineProps({
  initialZoom: {
      type: Number,
      required: true,
    },
    initialCoordinates: {
      type: Array,
      required: true,
    },
    gpsEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    gpsMode: {
      type: String,
      required: false,
      default: k.GPS_MODE_OFF,
    },
    shouldDisplayContent: {
      type: Boolean,
      required: true,
    }
});

const router = useRouter();
const route = useRoute();
const configStore = useConfigStore();
const gameStore = useGameStore();
const poiStore = usePoiStore();

// Local refs
const mapLoaded = ref(false);
const userMarker = ref(null);
const mapMovedByUser = ref(false);
const map = ref(null);
const gpsTargetMarker = ref(null);
const displayedPOIs = ref([]);
const { logError } = useErrorHandler();

// Store refs
const { appConfig } = storeToRefs(configStore);
const { currentTeam } = storeToRefs(gameStore);
const {
  currentLocation,
  currentPoi: currentPlace,
  mapBounds,
  activePois,
  poisObject,
  walkablePois: poisInRange
} = storeToRefs(poiStore);

// Keep computed with logic
const closestPOI = computed(() => 
  activePois.value.length > 0 ? activePois.value[0] : null
);

// Update watch with .value
watch(closestPOI, (newVal) => {
  if (newVal) {
    if (newVal !== null && newVal.distance !== null && newVal.distance <= 300) {
      if (mapMovedByUser.value === false) {
        centerMapOnClosestPOIs();
      }
    }
  }
});

watch(activePois, (newVal, oldVal) => {
  if (mapLoaded.value && route.name === 'home') {
        hidePOIs();
        drawDefaultPOIS();
        // prevents the map from centering on the user's location when the user is moving or the active pois are reloading
        if (newVal.length !== oldVal.length ) {
          centerMap();
        }
      }
});
// center the map when we enter the GPS routing preview
watch(currentPlace, (newVal) => {
  if (mapLoaded.value && newVal && route.name === 'placeRoutePreview') {
    centerMap();
  }
});

const onMapLoaded = () => {
  drawcurrentLocation();
  if (!mapLoaded.value) {
    emit('maploaded');
  }
  mapLoaded.value = true;
  map.value.on('dragstart', () => { mapMovedByUser.value = true; });
  map.value.on('move', () => { mapMovedByUser.value = true; });

  map.value.on('touchmove', () => { mapMovedByUser.value = true; });
  map.value.on('touchend', () => { mapMovedByUser.value = true; });
  map.value.on('wheel', () => { mapMovedByUser.value = true; });

  map.value.off('load', onMapLoaded);
  if (!mapMovedByUser.value) {
    centerMap();
  }
};

const drawcurrentLocation = () => {
  if (currentLocation.value) {
    if (isNaN(currentLocation.value.longitude || isNaN(currentLocation.value.latitude))) {
      Sentry.captureMessage('invalid user location type');
      return;
    }
    if (!userMarker.value) {
      const el = document.getElementById('usermarker');
      if (!el) {
        return;
      }
      el.className = 'mapboxgl-user-location-dot mapboxgl-marker mapboxgl-marker-anchor-center user-marker';
      userMarker.value = new mapboxgl.Marker(el).setLngLat(
        [currentLocation.value.longitude, currentLocation.value.latitude],
      ).addTo(map.value);
      if (mapMovedByUser.value === false) {
        centerMap();
      }
    } else {
      userMarker.value.setLngLat([currentLocation.value.longitude, currentLocation.value.latitude]);
    }
  }
};

const centerMap = ()=> {
  if (mapLoaded.value) {
    if (props.gpsMode === k.GPS_MODE_ON) {
      map.value.easeTo(
        {
          center: [currentLocation.value.longitude, currentLocation.value.latitude],
          zoom: DEFAULT_ZOOM,
        },
        {
          duration: MAP_EASE_DELAY,
        },
      );
      mapMovedByUser.value = false;
      return;
    }
    if (route.name === 'placeRoutePreview') {
      map.value.fitBounds(mapBounds.value,  mapBoundsOptions);
      mapMovedByUser.value = false;
      return;
    }
    if (poisInRange.value.length > 0) {
      mapMovedByUser.value = false;
      centerMapOnClosestPOIs();
      return;
    }
    if (!mapBounds.value || mapBounds.value.length < 2) {
      map.value.easeTo({ center: props.initialCoordinates, zoom: props.initialZoom });
      mapMovedByUser.value = false;
      return;
    }
    map.value.fitBounds(mapBounds.value,  mapBoundsOptions);
    mapMovedByUser.value = false;
  }
};

 /**
     * shortcut to draw the currently filtered pois
     */
const drawDefaultPOIS = () => {
  if (!activePois.value
    || activePois.value.length === 0) {
    return;
  }
  drawPOIs(activePois.value);
};
 /**
     * draws or refreshes the POIs layer on map.
     */
const drawPOIs = (pois) => {
  if (props.shouldDisplayContent === false) {
    return;
  }
  if (!map.value) {
    return;
  }
  if (displayedPOIs.value.length > 0) {
   displayedPOIs.value.forEach((p) => {
      p.hidden = false;
    });
    displayedPOIs.value = [];
  }
  pois.forEach((p) => {
    if (!p.icon) {
      Sentry.captureMessage(`trying to draw poi with id ${p.id}, ${p.name}, ${p.siteId} but no icon was found`);
    }
    const el = document.createElement('div');
    el.className = `marker-${p.icon}`;
    el.id = `${p.uuid}`;
      el.addEventListener('click', openCard);
    const coords = [p.longitude, p.latitude];

    const marker = new mapboxgl.Marker(el)
      .setLngLat(coords);
    marker.addTo(map.value);
    displayedPOIs.value.push(marker);
  });
};

/**
 * Hide the POI layer
 */
const hidePOIs = () => {
  displayedPOIs.value.forEach((p) => {
    // p.getElement().hidden = true;
    p.getElement().removeEventListener('click', openCard);
    p.remove();
    p = null;
  });
  displayedPOIs.value = [];
};
/**
     * Remove all layers and sources for GPS
     */
const hideGuidingRoute = () => {
  if (!map.value) {
    return;
  }
  if (map.value.getLayer(k.MBX_LAYER_NAME_GPS)) {
    map.value.removeLayer(k.MBX_LAYER_NAME_GPS);
  }
  if (map.value.getSource(k.MBX_SOURCE_NAME_GPS)) {
    map.value.removeSource(k.MBX_SOURCE_NAME_GPS);
  }

  if (gpsTargetMarker.value) {
    gpsTargetMarker.value.remove();
    gpsTargetMarker.value = null;
  }
};
const  centerMapOnClosestPOIs = () => {
  const bounds = new mapboxgl.LngLatBounds();
  poisInRange.value.forEach((p) => {
    bounds.extend([p.longitude, p.latitude]);
  });
  if (currentLocation) {
      bounds.extend([currentLocation.value.longitude, currentLocation.value.latitude]);
  }

  // sometimes the map isn't fully loaded when this call is made,
  // causing fitBounds to fail. Let's check that the map is loaded first
  if (map.value && map.value !== null) {
    map.value.fitBounds(bounds, mapBoundsOptions);
  }
};
 /**
     * draw the fastest walk path on map between an user and a target POI
     */
const drawGuidingRoute = async() => {
  if (appConfig.value.platformType === k.PLATFORM_TYPE.CITYPOLY) {
    hidePOIs();
    drawLocationMarker(currentPlace.value);
    return;
  }
  if (!props.gpsEnabled) {
    return;
  }
  if (!currentPlace.value) {
    return;
  }
  let urlMode = 'walking';
  if (appConfig.value.gpsMode === 'mapbox/cycling') {
    urlMode = 'cycling';
  }
  if (appConfig.value.gpsMode === 'mapbox/driving') {
    urlMode = 'driving';
  }
  const body = await getRoute(
    [currentLocation.value.longitude, currentLocation.value.latitude],
    [currentPlace.value.longitude, currentPlace.value.latitude],
    urlMode,
    mapboxgl.accessToken,
  );
  const alertSent = localStorage.getItem('noItineraryAlert');
  if (body === null) {
    if (!alertSent) {
        localStorage.setItem('noItineraryAlert', 'alertSent');
        Swal.fire({
        title: t('map.noItinerary.title'),
        text: t('map.noItinerary.content'),
        icon: 'warning',
      });
    }

    hidePOIs();
    drawLocationMarker(currentPlace.value);
    setTimeout(() => {
        drawGuidingRoute();
      }, 30000);
      return;
  };

  if (alertSent) {
    localStorage.removeItem('noItineraryAlert')
  }

  hidePOIs();
  drawLocationMarker(currentPlace.value);
  const guidingData = toGeoJson(body.routes[0].geometry.coordinates);
  const paintOptions = {
    'line-color': '#1c3d82',
    'line-width': 6,
    'line-opacity': 1,
    'line-dasharray': [0.1, 2.5],
  };
  drawLine(k.MBX_SOURCE_NAME_GPS, guidingData, k.MBX_LAYER_NAME_GPS, paintOptions);
  if (props.gpsMode === k.GPS_MODE_PREVIEW) {
    map.value.fitBounds(
      [[currentLocation.value.longitude, currentLocation.value.latitude], [currentPlace.value.longitude, currentPlace.value.latitude]],
      mapBoundsOptions,

    );
  }
};
const getRoute = async(source, dest, mode, mapboxToken) => {
  if (!Array.isArray(source) || source.length < 2) {
    Sentry.captureMessage('invalid source in getRoute');
    return null;
  }
  if (!Array.isArray(dest) || dest.length < 2) {
    Sentry.captureMessage('invalid dest in getRoute');
  }
  const url = `https://api.mapbox.com/directions/v5/mapbox/${mode}/${source[0]},${source[1]};${dest[0]},${dest[1]}?overview=full&geometries=geojson&access_token=${mapboxToken}`;
  try {
    const res = await api.fetchJSON(url);
    if (!res.routes || !Array.isArray(res.routes) || res.routes.length === 0) {
      throw new Error('empty routes response from mapbox');
    }
    return res;
  } catch (error) {
    logError(error, 'TheMap.getRoute');
    return null;
  }
};

/**
     * utility method to draw a line on the map
     */
const drawLine = (sourceName, sourceData, layerName, layerOptions) => {
  if (!map.value.getSource(sourceName)) {
    map.value.addSource(sourceName, { type: 'geojson', data: sourceData });
  } else {
    map.value.getSource(sourceName).setData(sourceData);
  }
  if (!map.value.getLayer(layerName)) {
    map.value.addLayer(makeMapboxLayer(sourceName, layerName, layerOptions));
  } else {
    map.value.setLayoutProperty(layerName, 'visibility', 'visible');
  }
};

    /**
     * draw a single POI marker on the map.
     */
const drawLocationMarker = (poi) => {
  if (gpsTargetMarker.value !== null) {
    gpsTargetMarker.value.remove();
  }
  const el = document.createElement('div');
  el.id = `${poi.id}`;
  el.addEventListener('click', (e) => openCard(e));
  el.className = `marker-${poi.icon}`;
  const coords = [poi.longitude, poi.latitude];

  const newTargetMarker = new mapboxgl.Marker(el)
    .setLngLat(coords)
    .addTo(map.value);
  gpsTargetMarker.value = newTargetMarker;
};

 // html event listener that opens the place card
const openCard = (e) => {

  const p = poisObject.value[e.target.id];
  if (!p) {
    Sentry.captureMessage(`trying to find poi with id ${e.target.id} but poi was not in poisObject`);
    return;
  }
  setCurrentPlace(p);
};

/**
     * method used on POIs click() handler to open a POI on click on its icon
     */
const setCurrentPlace = (place) => {
  const p = poisObject.value[place.uuid];
  mapMovedByUser.value = true;
  // OpenPlaceFunc will be triggered at the end of the animation.
  // once the place is open, it removes itself from the maps listeners
  const routerPush = () => { router.push({ name: 'placeView', params: { placeId: p.uuid } }); };
  const openPlaceFunc = () => {
    routerPush();
    map.value.off('moveend', openPlaceFunc);
  };
  map.value.on('moveend', openPlaceFunc);
  map.value.easeTo(
    {
      center: [place.longitude, place.latitude],
    },
    {
      duration: MAP_EASE_DELAY,
    },
  );
};

/**
 * Builds a geojson object based on coordinates
  */
const toGeoJson = (coordinates) => {
  return ({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
      },
    ],
  });
};
const makeMapboxLayer = (sourceName, layerName, paintOptions) => {
  return {
    id: layerName,
    type: 'line',
    source: sourceName,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: paintOptions,
  };
};
const testWebGL = () => {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  let res = false;
  if (gl && gl instanceof WebGLRenderingContext) {
    res = true;
  }
  canvas.remove();
  return res;
};


watch(() => currentLocation.value, (newLocation) => {
  if (newLocation && !isNaN(newLocation.longitude) && !isNaN(newLocation.latitude)) {
    if (userMarker.value) {
      userMarker.value.setLngLat([newLocation.longitude, newLocation.latitude]);
    } else {
      drawcurrentLocation();
    }
  }
});

onMounted(() => {
  if (testWebGL() === false) {
    Swal.fire({
      title: 'Impossible d\'afficher la carte',
      text: 'Votre navigateur est trop ancien pour afficher notre carte, merci de le mettre à jour',
      icon: 'warning',
    });

    return;
  }
  mapboxgl.accessToken = appConfig.value.mapboxToken;
  let mapOptions = {};
  if (!mapBounds.value || mapBounds.value.length < 2) {
    mapOptions = {
      container: 'map',
      style: appConfig.value.mapboxTheme,
      pitch: 45,
      center: props.initialCoordinates,
      zoom: props.initialZoom,
    };
  } else {
    mapOptions = {
      container: 'map',
      style: appConfig.value.mapboxTheme,
      pitch: 45,
      bounds: mapBounds.value,
      zoom: props.initialZoom,
    };
  }
  try {
    map.value = new mapboxgl.Map(mapOptions);
  } catch (error) {
    if (error.message.includes('Use a public access token')) {
      logError(error, 'TheMap.onMounted: mapBox token error');
      Swal.fire({
        title: t('map.init.titleTokenError'),
        text: t('map.init.tokenError'),
        icon: 'warning',
      });
      return;
    }
    logError(error, 'TheMap.onMounted: mapBox error');
  }
  if (map.value === null) {
    Swal.fire({
      title: t('map.init.titleLoadingError'),
      text: t('map.init.loadingError'),
      icon: 'error',
    });
  } else {
    map.value.on('load', onMapLoaded);
  }
});
  onBeforeMount(() => {
    if (appConfig.value.platformType !== 'citypoly') {
      if (currentTeam.value) {

        const translatedTitle = t('game.connected.title');
        Swal.fire({
          title: `${translatedTitle}, ${currentTeam.value.name} ! `,
          text: t('game.connected.content'),
          icon: 'success',
          confirmButtonText: t('game.connected.btn'),
        });
      } else {
        Swal.fire({
          title: t('game.notconnected.title'),
          text: t('game.notconnected.content'),
          icon: 'warning',
        });
      }
    }
  });
  defineExpose({
    drawDefaultPOIS,
    hideGuidingRoute,
    drawGuidingRoute,
    centerMap,
  })
</script>
<style lang="scss">

#usermarker {
  z-index: 10;
}
@media (max-width: 767px) {
  .mapboxgl-ctrl-directions {
    width: 100%;
    min-width: 100%;
    max-width:100%;
  }
  .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    margin: 0px 0px;
  }
  .mapbox-directions-instructions {
    height: 25vh;
  }
}

.mapbox-directions-route-summary h1 {
  color: #fff;
}

.mapboxgl-ctrl-top-left {
  z-index: 25;
}

</style>
