
import { computed } from 'vue';
import { useConfigStore } from '../pinia/configuration';
import * as Sentry from '@sentry/vue';

export const useErrorHandler  = () => {
  const  store = useConfigStore();
  const enableConsole = computed(() => store.appConfig.enableConsole);
  const enableSentry = computed(() => store.appConfig.enableSentry);

  const logError = (err, msg = null) => {
    if (enableConsole.value === true) {
      console.error(msg, err);
    }
    if (enableSentry.value === true) {
      Sentry.captureException(err);
    }
  }

  const logMessage = (msg) => {
    if (enableConsole.value === true) {
      console.log(msg);
    }
    if (enableSentry.value === true) {
      Sentry.captureMessage(msg);
    }
  }

  return { logError, logMessage };
}
