<template>
  <div
    id="notify-target"
    class="notify notify--target container is-expanded"
  >
    <div
      id="notify__department__content_scroller"
      class="notify__department__content"
    >
      <div class="main-container">
        <div class="dice-container">
          <div
            :class="['dice', { spinning: isSpinning }]"
            :style="{
              transform: `rotateX(${rotationX}deg) rotateY(${rotationY}deg)`,
              transitionDuration: rotationDuration,
            }"
          >
            <div class="face front">
              <div class="dot dot-center" />
            </div>
            <div class="face back">
              <div class="dot dot-top-left" />
              <div class="dot dot-bottom-right" />
            </div>
            <div class="face right">
              <div class="dot dot-top-left" />
              <div class="dot dot-center" />
              <div class="dot dot-bottom-right" />
            </div>
            <div class="face left">
              <div class="dot dot-top-left" />
              <div class="dot dot-top-right" />
              <div class="dot dot-bottom-left" />
              <div class="dot dot-bottom-right" />
            </div>
            <div class="face top">
              <div class="dot dot-top-left" />
              <div class="dot dot-top-right" />
              <div class="dot dot-center" />
              <div class="dot dot-bottom-left" />
              <div class="dot dot-bottom-right" />
            </div>
            <div class="face bottom">
              <div class="dot dot-top-left" />
              <div class="dot dot-top-right" />
              <div class="dot dot-middle-left" />
              <div class="dot dot-middle-right" />
              <div class="dot dot-bottom-left" />
              <div class="dot dot-bottom-right" />
            </div>
          </div>
        </div>
      </div>
      <div class="notify__footer">
        <PanelBtn
          :title="$t(pfx + 'throw')"
          @click="throwDice"
        />
      </div>
    </div>
    <ErrorPopup
      v-if="shouldDisplayErrorAlert"
      :title="$t(pfx + 'errorTitle')"
      :message="$t(pfx + 'errorMessage')"
      :button-text="$t(pfx + 'errorBtn')"
      :open="true"
      @close="shouldDisplayErrorAlert = false"
    />
  </div>
</template>

<script setup>
import { computed,  ref } from 'vue';
import { rollDice } from '../../api/citypoly';
import PanelBtn from '../map/PanelBtn.vue';
import ErrorPopup from '../ErrorPopup.vue';

import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { usePoiStore } from '../../pinia/poi';
import { useErrorHandler } from '../../composables/handleErrors';
const gameStore = useGameStore();
const emit = defineEmits(['newAction']);
const pfx = 'components.citypoly.PannelDice.';
const poiStore = usePoiStore();
const configStore = useConfigStore()
const { logError } = useErrorHandler();

const isSpinning = ref(false);
const rotationX = ref(15);
const rotationY = ref(15);
const rotationDirection = ref(1);
const rotationDuration = ref('3000ms');

const coords = computed(() => poiStore.currentLocation);
const shouldDisplayErrorAlert = ref(false);
const diceAction = ref(null);

const appConfig = computed(() => configStore.appConfig);

const postRollDice = async () => {
  try {
    const res = await rollDice(appConfig.value.gameApiBaseUrl, coords.value, gameStore.currentToken);
    return res;
  } catch (error) {
    logError(error, 'PanelDice.postRollDice');
    shouldDisplayErrorAlert.value = true;
  }
};

const throwDice = () => {
  if (isSpinning.value || shouldDisplayErrorAlert.value) return;
  const randomDuration = (Math.random() * 2.5 + 1).toFixed(1);
  isSpinning.value = true;
  rotationDuration.value = `${randomDuration}s`;
  rotationDirection.value *= -1;

  const extraSpins = Math.floor(parseFloat(randomDuration));
  const targetFace = Math.floor(Math.random() * 6) + 1;
  const { angleX, angleY } = getRotationAngles(targetFace);

  rotationX.value += rotationDirection.value * (extraSpins * 360 + angleX);
  rotationY.value += rotationDirection.value * (extraSpins * 360 + angleY);

  setTimeout(async () => {
    diceAction.value = await postRollDice();
    if (!diceAction.value) {
      shouldDisplayErrorAlert.value = true;
    } else {
      emit('newAction', diceAction.value);
      diceAction.value = null;
    }
  }, randomDuration * 800);

  setTimeout(() => {
    isSpinning.value = false;
  }, randomDuration * 1000);
};

const getRotationAngles = (face) => {
  switch (face) {
    case 1:
      return { angleX: 0, angleY: 0 };
    case 2:
      return { angleX: -90, angleY: 0 };
    case 3:
      return { angleX: 0, angleY: -90 };
    case 4:
      return { angleX: 0, angleY: 90 };
    case 5:
      return { angleX: 90, angleY: 0 };
    case 6:
      return { angleX: -90, angleY: 180 };
    default:
      return { angleX: 0, angleY: 0 };
  }
};
</script>

<style lang="scss" scoped>
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.main-container {
  background-image: linear-gradient(to right, #ffe55e, #ffcf57, #ffba55, #ffa557, #ff915b);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  padding-bottom: 10vh;
}
.dice-container {
  width: 200px;
  height: 200px;
  perspective: 1000px;
  perspective-origin: 150% 150%;
  margin: 100px auto 0;
  border: none;
}

.dice {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  border: none;
  transition: transform 0.3s ease;
}

.spinning {
  transition: transform var(--rotation-duration, 3s) ease;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  border: 1px solid #ffffff;
  box-shadow: inset 0px 0px 0px 1px #ffffff;
}

.face::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: #ffffff;
  border-radius: inherit;
  border: solid 6px #f0f0f0;
}

.top {
  transform: rotateX(90deg) translateZ(99px);
}
.bottom {
  transform: rotateX(-90deg) translateZ(99px);
}
.right {
  transform: rotateY(90deg) translateZ(99px);
}
.left {
  transform: rotateY(-90deg) translateZ(99px);
}
.front {
  transform: rotateX(0deg) translateZ(99px);
}
.back {
  transform: rotateX(-180deg) translateZ(99px);
}

.dot {
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
}

.dot-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}

.dot-top-left {
  top: 10%;
  left: 10%;
  backface-visibility: hidden;
}

.dot-top-right {
  top: 10%;
  right: 10%;
  backface-visibility: hidden;
}

.dot-middle-left {
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  backface-visibility: hidden;
}

.dot-middle-right {
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  backface-visibility: hidden;
}

.dot-bottom-left {
  bottom: 10%;
  left: 10%;
  backface-visibility: hidden;
}

.dot-bottom-right {
  bottom: 10%;
  right: 10%;
  backface-visibility: hidden;
}

.btn-dice {
  padding: 1rem;
  margin: auto;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
}
</style>
