<template>
  <div class="faq-container">
    <div class="faq-close-btn">
      <close-btn
        :icon="`/themes/${theme}/images/icons/ico-close-transparent.svg`"
        :width="100"
        :height="100"
        @clicked="onCloseFaq"
      />
    </div>
    <div class="faq-phone"
      v-if="phoneNumber && phoneNumber.length  > 0">
      <h1>{{ $t('components.gameFaq.titlePhoneNumber') }}</h1>
      <p>{{ $t('components.gameFaq.textPhoneNumber') }} <strong>{{  phoneNumber }}</strong></p>
    </div>
    <div
      class="faq-content"
      v-html="faqText"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import closeBtn from '../BtnRound.vue';
import { useConfigStore } from '../../pinia/configuration';
const props = defineProps({
  faqText: {
    type: String,
    required: false,
    default: '',
  },
  phoneNumber: {
    required: false,
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const configStore = useConfigStore();
const theme = computed(() => configStore.appConfig.cssTheme);

const onCloseFaq = () => {
  emit('close');
};
</script>
