<template>
  <form>
    <div
      v-for="question in questions"
      :key="question.id"
    >
      <div class="quizz_question_title">
        {{ question.title }}
      </div>
      <div v-if="question.type === 'image'">
        <panel-module-image :image-src="question.fileUrl" />
        <clues-question :question-id="question.id" />
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            v-model="answers[question.id]"
            type="radio"
            :value="p.id"
            :name="`poll_${question.id}`"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.text }}</span>
          </span>
        </label>
      </div>
      <div v-if="question.type === 'video'">
        <panelModuleVideo
          title=""
          :url="question.fileUrl"
          :should-loop="false"
          :should-autoplay="false"
        />
        <clues-question
          :question-id="question.id"
        />
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            v-model="answers[question.id]"
            type="radio"
            :value="p.id"
            :name="`poll_${question.id}`"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.text }}</span>
          </span>
        </label>
      </div>
      <div v-if="question.type === 'audio'">
        <figure>
          <audio
            controls
            :src="question.fileUrl"
          />
        </figure>
        <clues-question
          :question-id="question.id"
        />
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            v-model="answers[question.id]"
            type="radio"
            :value="p.id"
            :name="`poll_${question.id}`"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.text }}</span>
          </span>
        </label>
      </div>
      <div v-if="question.type === 'single' || question.type === 'multiple'">
        <clues-question
          :question-id="question.id"
        />
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            v-model="answers[question.id]"
            :type="question.type === 'single' ? 'radio' : 'checkbox'"
            :value="p.id"
            :name="`poll_${question.id}`"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.text }}</span>
          </span>
        </label>
      </div>
      <div v-else-if="question.type === 'open' || question.type === 'team_name'">
        <clues-question
          :question-id="question.id"
        />
        <textarea
          v-model="answers[question.id]"
          :placeholder="$t('components.quizzForm.openQuestionAnswerPlaceHolder')"
          style="direction:ltr;width:100%;min-height:80px"
          autocomplete="off"
          spellcheck="off"
          maxlength="1024"
        />
      </div>
      <div v-else-if="question.type === 'photo'">
        <clues-question
          :question-id="question.id"
        />
        <base-image-input v-model="answers[question.id]" />
      </div>
    </div>

    <div class="quizz__answer__container">
      <ButtonLoading
        :loading="loading"
        :disabled="buttonDisabled"
        :styled="false"
        class="btn btn--unshadow btn--secondary"
        @click="submitPoll()"
      >
        {{ buttonText }}
      </ButtonLoading>
    </div>
  </form>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import quizzApi from '../../api/quizz';
import panelModuleVideo from './PanelModuleVideo.vue';
import panelModuleImage from './PanelModuleImage.vue';
import ButtonLoading from '../ButtonLoading.vue';
import BaseImageInput from '../BaseImageInput.vue';
import CluesQuestion from './CluesQuestion.vue';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { ref , computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useErrorHandler } from '../../composables/handleErrors';

const  createAnswers = (questions) =>{
  const answers = {};
  if (questions) {
    questions.forEach((q) => {
      if (q.type === 'multiple') {
        answers[q.id] = [];
      } else {
        answers[q.id] = '';
      }
    });
  }
  return answers;
}
const emit = defineEmits(['success']);
const gameStore = useGameStore();
const configStore = useConfigStore();
const { logError } = useErrorHandler();
const v$ = useVuelidate();
const { t} = useI18n();
const props = defineProps({
  questions: {
    type: Array,
    required: true,
  },
  quizzId: {
    type: String,
    required: true,
  },
});

const loading = ref(false);
const answers = ref(createAnswers(props.questions));

const appConfig = computed(() => configStore.appConfig);
const user = computed(() => gameStore.currentTeam);
const token = computed(() => gameStore.currentToken);
const teamId = computed(() => gameStore.currentTeam ? gameStore.currentTeam.id : null);
const teamIsBlocked = computed(() => gameStore.teamIsBlocked);

const buttonDisabled = computed(() => loading.value === true || v$.$invalid || teamIsBlocked.value === true);
const buttonText = computed(() => {
  if (teamIsBlocked.value === true) {
    return t('components.quizzForm.submitTeamBlocked');
  }
  return t('components.quizzForm.submit');
});

const mustBeCool = (value) => value.length > 0;

const fileIsImage = (value) => {
  if (!value) {
    return false;
  }
  const isFile = value instanceof File;
  const isBlob = value instanceof Blob;
  if (isFile !== true && isBlob !== true) {
    return false;
  }
  return value.type.startsWith('image');
};

const  getValidators = (questions) => {
  const obj = {};
  if (questions) {
    questions.forEach((question) => {
      if (question.type === 'single' || question.type === 'picture') {
        obj[question.id] = {
          required,
        };
      } else if (question.type === 'multiple') {
        obj[question.id] = { mustBeCool };
      } else if (question.type === 'open') {
        obj[question.id] = {
          required,
          minLength: minLength(1),
          maxLength: maxLength(1024),
        };
      } else if (question.type === 'photo') {
        obj[question.id] = {
          required,
          fileIsImage,
        };
      }
    });
  }
  return obj;
}

const validations = () => {
    return {
      answers: getValidators(props.questions.value),
    };
  };
// ICI

watch(answers, () => {
  v$.value.$touch();
}, { deep: true });

onMounted(() => {
  v$.value.$touch();
});
const submitPoll = async () => {
  if (loading.value === true) {
    return;
  }
  loading.value = true;
  const request = [];

  for (let i = 0; i < props.questions.length; i += 1) {
    const question = props.questions[i];
    if (question.type === 'multiple') {
      request.push({
        questionId: question.id,
        propositionIds: [...answers.value[question.id]],
      });
    } else if (
      question.type === 'single'
      || question.type === 'video'
      || question.type === 'audio'
      || question.type === 'image'
    ) {
      request.push({
        questionId: question.id,
        propositionIds: [answers.value[question.id]],
      });
    } else if (question.type === 'open' || question.type === 'team_name') {
      request.push({
        questionId: question.id,
        answerText: ` ${answers.value[question.id]}`.slice(1),
      });
    } else if (question.type === 'photo') {
      let result;
      try {

        result = await quizzApi.postImage(
          appConfig.value.gameApiBaseUrl,
          answers.value[question.id],
          teamId.value,
          token.value,
        );
      } catch (error) {
        logError(error, 'QuizzForm.submitPoll');
        loading.value = false;
        return;
      }

      if (result) {
        request.push({
          questionId: question.id,
          answerText: result.url,
        });
      } else {
        loading.value = false;
        Swal.fire({
          title: `${t('components.quizzForm.error.title')}`,
          text: `${t('components.quizzForm.error.img')}`,
          icon: 'error',
        });
        return;
      }
    }
  }

  const answerReq = {
    partyId: user.value.partyId,
    teamId: teamId.value,
    answers: request,
  };
  try {
    const result = await quizzApi.postAnswer(
      appConfig.value.gameApiBaseUrl,
      props.quizzId,
      answerReq,
      token.value,
    );
    if (result && result.saved) {
      emit('success');
    } else {
      loading.value = false;
      Swal.fire({
        title: `${t('components.quizzForm.error.title')}`,
        text: `${t('components.quizzForm.error.content')}`,
        icon: 'error',
      });
    }
  } catch (e) {
    logError(e, 'QuizzForm.submitPoll');
    if (e.message.search('Conflict') >= 0) {
      Swal.fire({
        title: `${t('components.quizzForm.error.partyStateTitle')}`,
        text: `${t('components.quizzForm.error.partyStateContent')}`,
        icon: 'info',
      });
      return;
    }
    Swal.fire({
      title: `${t('components.quizzForm.error.title')}`,
      text: `${t('components.quizzForm.error.content')}`,
      icon: 'error',
    });
    const jsonReq = JSON.stringify(answerReq);
  }
};
</script>
<style scoped>
.quizz_question_title {
  padding-top: 16px;
  padding-bottom: 8px;
  font-weight: bold;
}
</style>

