import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia';
import 'intersection-observer';
import VueScrollTo from 'vue-scrollto';
import VueMatomo from 'vue-matomo';
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import VueObserveVisibility from 'vue-observe-visibility';
import VueLazyload from 'vue-lazyload';

import * as Sentry from '@sentry/vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import 'vue-material-design-icons/styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue';
import initRouter from './router';

import CloudImage from './components/CloudImage.vue';
import ResponsiveImage from './components/image/ResponsiveImage.vue';
import LightBoxImage from './components/image/LightboxPicture.vue';

import en from './locales/en.json';
import fr from './locales/fr.json';
import { useConfigStore } from './pinia/configuration';

async function startApp() {
  // Load the app runtime configuration
  const app = createApp(App);

  const i18n = createI18n({
    messages: {
      en,
      fr,
    }
  });

  let config = null;


  app.use(createPinia());
  const configStore = useConfigStore();
  config = await configStore.fetchConfig();
  app.use(i18n);





  // load global components
  app.component('CloudImage', CloudImage);
  app.component('RespImage', ResponsiveImage);
  app.component('LightboxImg', LightBoxImage);
  // Load plugins
  app.use(VueLazyload, {
    lazyComponent: true,
  });
  app.use(VueLoaders);
  app.use(VueScrollTo);
  app.use(VueObserveVisibility);
  app.use(VueToast);

  const router = initRouter(config);

  if (config.matomoSiteId > 0) {
    app.use(VueMatomo, {
      host: 'https://stats.dotmap.fr',
      siteId: config.matomoSiteId,
      router,
    });
  }
  app.use(router);

   // initialize sentry

   Sentry.init({
    app,
    dsn: 'https://f6b159ed8c0d9a1d09e68364e2033dd1@o238725.ingest.us.sentry.io/4508444625862656',
    environment: config.devMode ? 'dev' : 'prod',
    logErrors: true,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/api\.dotmap\.fr\/api/, /^https:\/\/gameapi\.dotmap\.fr\/gameapi/],
    tracesSampleRate: 0.01,
    profilesSampleRate: 0.1,
    replaysSessionSampleRate: 0.001, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracingOptions: {
      trackComponents: true,
    },
  });

  app.mount('#app');
}

try {
  startApp();
} catch (error) {
  console.log('unable to start the app', error);
}
