<template>
  <transition name="fadeout">
    <initial-loading v-if="!loaded" />
    <div
      v-else
      class="wrapper"
      :class="wrapperClass"
    >
      <div class="backdrop" />
      <lightbox-container
        :show="shouldShowLightBox === true"
        :picture-path="lightboxPicture.pictures ? lightboxPicture.pictures : ''"
        :pictures="lightboxPicture.pictures"
        :initial-index="lightboxPicture.index"
        @hide="hideLightbox"
      />
      <the-nav
        :theme="theme"
        :open="mainMenuOpen"
        @togglemenu="toggleMainMenu"
      />
      <solde-component
        v-if="destination.platformType === 'citypoly'"
        :new-balance="cityflouz"
      />
      <button-group
        v-if="shouldShowMainMenu === true"
        :user-balance="team ? team.score : -1"
        :gps-button-icon="`/themes/${theme}/images/icons/ico-paperplane.svg`"
        :enable-login="appConfig.enableLogin"
        :main-menu-open="mainMenuOpen"
        :enable-help-btn="destination.enableHelpButton"
        :theme="theme"
        :enable-chat-btn="appConfig.enableChat"
        @togglemenu="toggleMainMenu"
        @resetmap="resetMap()"
        @close-rules="displayRules = false"
        @display-chat="onDisplayChat"
      />
      <div
        v-else
        class="button-group-fixed"
      >
        <btn-round
          :icon="`/themes/${theme}/images/icons/ico-paperplane.svg`"
          @clicked="resetMap()"
        />
        <btn-round
          v-if="destination.enableHelpButton === true"
          :icon="`/themes/${theme}/images/icons/ico-info.svg`"
          :width="100"
          :height="100"
          @clicked="onClickInfo"
        />
        <btn-round
          v-if="destination.platformType === k.PLATFORM_TYPE.CITYPOLY"
          :icon="`/themes/${theme}/images/icons/ico-home.svg`"
          :width="30"
          :height="30"
          @clicked="displayCitypolyProperties = true"
        />
        <a
          class="messages-container"
          :class="{ 'breathing-animation': chatStore && hasNewMessage }"
          @click="onDisplayChat"
        >
          <btn-round
            :icon="`/themes/${theme}/images/icons/ico-chat.svg`"
            :width="30"
            :height="30"
          />
          <div
            v-if="chatStore && hasNewMessage"
            class="messages-puce"
          />
        </a>
      </div>
      <div class="main">
        <!-- Alert for Citypoly -->
        <team-block-alert
          v-if="teamIsBlocked"
          :show="teamIsBlocked"
          :title="$t('map.teamBlocked.title')"
          :content="$t('map.teamBlocked.blockDuration')"
          :block-until="teamBlockEndDate"
          @timer-is-over="refreshData()"
        />
        <!-- Alert for Citypoly -->
        <TeamGoodAlert
          :show="teamReceivedRent"
          :message="$t('map.teamGoodAlert.rentRecieved')"
          :close-timeout="30"
          @close="teamReceivedRent = false"
        />
        <div class="section-map section-map--short">
          <the-map
            v-if="destination"
            ref="map"
            :initial-coordinates="[destination.longitude, destination.latitude]"
            :initial-zoom="destination.zoom"
            :gps-mode="gpsMode"
            :should-display-content="shouldDisplayContent"
            @maploaded="onMapLoaded"
          />
          <transition name="placeindicator">
            <place-indicator
              v-if="shouldDisplayCitypolyPoiIndicator"
              :poi="currentPOI"
            />
          </transition>
          <div class="section__actions">
            <rectangle-button
              v-if="shouldDisplayFilterButton === true"
              :title="filterButtonText"
              faded
              :icon="`/themes/${theme}/images/icons/ico-location-blue.svg`"
              @click="filterButtonClicked"
            />
          </div>
        </div>
      </div>
      <categories-menu
        v-if="categoriesMenuOpen === true"
        :expanded="categoriesMenuOpen === true"
        @close="categoriesMenuOpen = false"
      />
      <!--<card-manager /> -->
      <city-panel
        v-if="destination.platformType !== 'citypoly'"
        :should-display-content="shouldDisplayContent"
      />
      <panel-container
        :poi="currentPOI"
        :closest-poi="closestPoi"
        :expanded="poiPanelOpen"
        :hide-checkin-popup="destination.hideCheckinPopup"
        @go-to-poi="goToPoi"
      />
      <transition name="rules">
        <GameFaq
          v-if="displayRules === true"
          :faq-text="destination.faqText"
          :phone-number="party ? party.phoneNumber : ''"
          @close="displayRules = false"
        />
      </transition>
      <transition name="slideinfo">
        <PropertyTitlesList
          v-if="displayCitypolyProperties === true"
          @close="displayCitypolyProperties = false"
        />
      </transition>
      <transition name="slideinfo">
        <ChatComponent
          v-if="displayChat === true"
          @close="displayChat = false, hasNewMessage = false"
        />
      </transition>
    </div>
  </transition>
</template>

<script setup>
import Swal from 'sweetalert2';
import * as k from '../constants';
import InitialLoading from '../components/map/InitialLoader.vue';
import TheNav from '../components/TheNav.vue';
import RectangleButton from '../components/ButtonRectangle.vue';
import ButtonGroup from '../components/ButtonGroup.vue';
import CategoriesMenu from '../components/categories/CategoriesMenu.vue';
import TheMap from '../components/TheMap.vue';
import CityPanel from '../components/map/PanelCity.vue';
import PanelContainer from '../components/map/PanelContainer.vue';
import LightboxContainer from '../components/image/LightboxContainer.vue';
import BtnRound from '../components/BtnRound.vue';
import GameFaq from '../components/map/GameFaq.vue';
import TeamBlockAlert from '../components/map/TeamBlockAlert.vue';
import TeamGoodAlert from '../components/map/TeamGoodAlert.vue';
import SoldeComponent from '../components/citypoly/SoldeComponent.vue';
import PropertyTitlesList from '../components/citypoly/PropertyTitlesList.vue';
import ChatComponent from '../components/chat/ChatComponent.vue';
import PlaceIndicator from '../components/citypoly/PlaceIndicator.vue';
import { useConfigStore } from '../pinia/configuration';
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch } from 'vue';
import { useDestinationStore } from '../pinia/destination';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUIStore } from '../pinia/uistate';
import { useGameStore } from '../pinia/game';
import { usePoiStore } from '../pinia/poi';
import { useErrorHandler } from '../composables/handleErrors';
import { useCategoriesStore } from '../pinia/categories';
import { useChatStore } from '../pinia/chat';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const uiStore = useUIStore();
const gameStore = useGameStore();
const chatStore = useChatStore();
const poiStore = usePoiStore();
const { t } = useI18n();
const configStore = useConfigStore();
const destinationStore = useDestinationStore();
const categoriesStore = useCategoriesStore();
const { logError } = useErrorHandler();

// stores refs
const { currentDestination: destination } = storeToRefs(destinationStore);
const { hasNewMessage } = storeToRefs(chatStore);
const { locationStatus } =  storeToRefs(poiStore);
const { appConfig } = storeToRefs(configStore);

const gpsMode = ref(k.GPS_MODE_OFF);
const platformType = ref(k.PLATFORM_TYPE.CITYPOLY);
const categoriesMenuOpen = ref(false);
const mainMenuOpen = ref(false);
const displayRules= ref(false);
const isRefreshingData = ref(false);
const initialLoadingDone = ref(false);
const displayCitypolyProperties = ref(false);
const teamReceivedRent = ref(false);
const displayChat = ref(false);
const mapRef = useTemplateRef('map')
const dataRefresher = ref(null);

// UI Store refs
const { 
  poiPanelOpen,
  lightboxPicture,
  hasOpenPanel: backdrop 
} = storeToRefs(uiStore);

// POI Store refs
const { 
  currentPoi: currentPOI,
  poisObject: placesObject,
  activePois: pois 
} = storeToRefs(poiStore);

// Game Store refs
const {
  currentTeam: team,
  currentParty: party,
  teamIsBlocked,
  teamBlockEndDate,
  citypolyPropertyTitles
} = storeToRefs(gameStore);

const cityflouz = computed(() => team.value ? team.value.score : 0);
const loaded = computed(() => initialLoadingDone.value === true && destination.value !== null);
const theme = computed(() => appConfig.value.cssTheme);
const teamAuthenticated = computed(() => team.value !== null);
const shouldDisplayContent = computed(() => {
  if (appConfig.value.restrictContentToPlayers) {
    if (!team.value) {
      return false;
    }
    return true;
  }
  return true;
});

const shouldDisplayCitypolyPoiIndicator = computed(() => {
  if (destination.value.platformType !== k.PLATFORM_TYPE.CITYPOLY) {
    return false;
  }
  if  (!currentPOI.value) {
    return false;
  }
  if (route.name === 'placeRoutePreview') {
    return true;
  }
  return false;
});

const closestPoi = computed(() => {
  if (pois.value.length > 0 && pois.value[0].distance <= appConfig.value.baseCheckinDistance) {
    return pois.value[0];
  }
  return null
});
const shouldShowLightBox = computed(() => lightboxPicture.value !== null && lightboxPicture.value.pictures != null);

const shouldShowMainMenu = computed(() => {
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY) {
    return false;
  }
  if (
    appConfig.value.enableLogin === false &&
    appConfig.value.enableRegistration === false
  ) {
    return false;
  }
  return true;
});

const shouldDisplayFilterButton = computed(() => {
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY) {
    return false;
  }
  if (gpsMode.value === k.GPS_MODE_OFF) {
    return appConfig.value.enableCategoriesList;
  }
  return true;
});

const filterButtonText = computed(() => {
  if (gpsMode.value === k.GPS_MODE_OFF) {
    return t('map.buttons.place_filter');
  }
  return t('map.buttons.place_filter_map');
});
const wrapperClass = computed(()=> {
  let className = '';
  if (mainMenuOpen.value === true) {
    className += ' navigation-displayed';
  }
  if (backdrop.value === true) {
    className += ' has-visible-backdrop';
  }
  return className;
});

const dispatchRoute = ()=> {
      // loaded server-side as the result of Facebook Shares
  switch (route.name) {
    case 'placeView':
      gpsMode.value = k.GPS_MODE_OFF;
        showPlace(route.params.placeId);

      break;
    case 'placeRoutePreview':
      gpsMode.value = k.GPS_MODE_PREVIEW;
      placeRoutePreview(route.params.poiId);
      break;
    case 'placeRoute':
      gpsMode.value = k.GPS_MODE_ON;
      placeRoutePreview(route.params.poiId);
      break;

    default:
      gpsMode.value = k.GPS_MODE_OFF;
      poiStore.currentPoi = null;
      uiStore.closePOIPanel();
      mapRef.value.hideGuidingRoute();
      mapRef.value.drawDefaultPOIS();
      mapRef.value.centerMap();
      break;
  }
};
const placeRoutePreview = (placeIdentifier) => {
  const place = placesObject.value[placeIdentifier];
  if (place) {
    poiStore.currentPoi = place;
    uiStore.closePOIPanel();
    mapRef.value.drawGuidingRoute();
  }
};
const showPlace = (placeIdentifier) => {
    const place = placesObject.value[placeIdentifier];
    if (place) {
      poiStore.currentPoi = place;
      uiStore.openPOIPanel();
    } else {
      router.push('/');
    }

  mapRef.value.hideGuidingRoute();
};

const filterButtonClicked = () => {
  if (gpsMode.value === k.GPS_MODE_OFF) {
    categoriesMenuOpen.value = true;
  } else if (gpsMode.value === k.GPS_MODE_ON || gpsMode.value === k.GPS_MODE_PREVIEW) {
    gpsMode.value = k.GPS_MODE_OFF;
    router.push({ name: 'placeView', params: { placeId: route.params.poiId } });
  }
};

const resetMap = () => {
  mapRef.value.centerMap();
};

const hideLightbox = () => {
  uiStore.lightboxPicture = ({ pictures: null, index: 0 });
};
const toggleMainMenu = () => {
  mainMenuOpen.value = !mainMenuOpen.value;
};

const onMapLoaded = () => {
  dispatchRoute();
};

const onClickInfo = () => {
  displayRules.value = true;
};

const goToPoi = async (poiUuid) => {
  try {
    router.push({ name: 'placeRoutePreview', params: { poiId: poiUuid } });
  } catch (error) {
    logError(error, 'Map.goToPoi');
  }
};

const refreshData = async() => {
  if (isRefreshingData.value === true) {
    return;
  }
  isRefreshingData.value = true;

  // fetch the destination
  let dest = null;
  try {
    dest = await destinationStore.fetchDestination();
    if (!dest) {
      throw new Error('could not fetch initial destination.');
    }
  } catch (error) {
    isRefreshingData.value = false;
    logError(error, 'Map.refreshData, fetching destination');
    return;
  }
  const siteId = dest.id;
  // fetch the other assets
  try {
    await Promise.all([
      categoriesStore.fetchCategories(siteId, appConfig.value.lang),
      poiStore.getPois(),
    ]);
    if (appConfig.value.enableChat === true) {
      await chatStore.getChatMessages();
    }
    if (appConfig.value.platformType !== k.PLATFORM_TYPE.CITYPOLY) {
      await gameStore.loadTeamContext();
    } else {
      await gameStore.loadTeamContextCitypoly();
    }
    initialLoadingDone.value = true;
  } catch (error) {
    logError(error, 'Map.refreshData, loading team context');
    if (
      teamAuthenticated.value === false &&
      appConfig.value.restrictContentToPlayers === true &&
      appConfig.value.enableRegistration === true &&
      route.name !== 'Register'
    ) {
      initialLoadingDone.value = true;
      isRefreshingData.value = false;
      router.push({ name: 'Register' });
      return;
    }
  }
    isRefreshingData.value = false;
    initialLoadingDone.value = true;
};

const citypolySseActions = () =>  {
  const eventSource = new EventSource(
    `${appConfig.value.gameApiBaseUrl}/modules/citypoly/parties/${party.value.id}/events`
  );
  eventSource.addEventListener('dmp', async (event) => {
    const sseEventData = JSON.parse(event.data);
    if (
      sseEventData.actionType === k.TEAM_ACTIONS.RENT_RECIEVED &&
      citypolyPropertyTitles.value.length <= 0
    ) {
      return;
      } else {
        const propertyTitleTargeted = citypolyPropertyTitles.value.find((propertyTitle) => {
        return propertyTitle.poiId === sseEventData.poiId;
      });
      if (!propertyTitleTargeted) {
        return;
      } else {
        teamReceivedRent.value = true;
        await gameStore.fetchCurrentTeam();
      }
    }
  });
};

const onDisplayChat = () => {
  displayChat.value = true;
  hasNewMessage.value = false;
};

watch(route, () => {
  dispatchRoute();
})

watch(() => locationStatus.value, () => {
  if (locationStatus.value === k.LOCATION_STATUS.DENIED) {
    Swal.fire({
      title: t('map.noGeolocation.alertTitle'),
      text: t('map.noGeolocation.alertText'),
      icon: 'warning',
    });
  }
});

onMounted(async() => {
  console.log('Map mounted');
  if (appConfig.value.enableGeolocation === true) {
    if (locationStatus.value === k.LOCATION_STATUS.DENIED) {
      Swal.fire({
        title: t('map.noGeolocation.alertTitle'),
        text: t('map.noGeolocation.alertText'),
        icon: 'warning',
      });
    }
    poiStore.startLocationWatcher();
  }
  await refreshData();
  platformType.value = appConfig.value.platformType;
  dataRefresher.value = setInterval(async () => {
    await refreshData();
  }, appConfig.value.dataUpdateInterval * 1000);
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY && party.value) {
    citypolySseActions();
  }
});
</script>
<style lang="scss" scoped>
.rules-enter-active,
.rules-leave-active {
  transition: opacity 0.6s;
}
.rules-enter,
.rules-leave-to {
  opacity: 0;
}

.fadeout-enter-active,
.fadeout-leave-active {
  transition: opacity 0.6s;
}
.fadeout-enter-from,
.fadeout-leave-to {
  opacity: 0;
}
.slideinfo-leave-active,
.slideinfo-enter-active {
  transition: 0.4s ease-in-out;
}
.slideinfo-enter-from {
  transform: translateY(100%);
  margin-top: 0px;
}
.slideinfo-leave-to {
  transform: translateY(100%);
}
.messages-container {
  margin-top: .5rem;
  position: relative;
  cursor: pointer;
}
.messages-puce {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  border: white 2px solid;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.breathing-animation {
  animation: breathing 1s ease-in-out infinite;
}
.placeindicator-enter-active, .placeindicator-leave-active {
  transition: all 0.3s ease-in-out;
}

.placeindicator-enter-from,
.placeindicator-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
