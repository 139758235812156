import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useGameStore } from './game';
import { getMessages } from '../api/chat';
import { useErrorHandler } from '../composables/handleErrors';
import { useConfigStore } from './configuration';

export const useChatStore = defineStore('chat', () => {
  const gameStore = useGameStore();
  const { logError } = useErrorHandler();
  const appConfig = useConfigStore().appConfig;

  const chatMessages = ref([]);
  const hasNewMessage = ref(false);

  const currentToken = computed(() => gameStore.currentToken);
  const team = computed(() => gameStore.currentTeam);

  const getChatMessages = async () => {
    try {
      const res = await getMessages(appConfig.gameApiBaseUrl, team.value.id, currentToken.value);
      res.sort((a, b) => new Date(a.sentAt) - new Date(b.sentAt));
      if (chatMessages.value.length === 0 && res.length > 0) {
        hasNewMessage.value = true;
      }
      chatMessages.value = res;
    } catch (error) {
      logError(error, 'ChatComponent.getMessagesList');
    }
  };

  watch(
    () => chatMessages.value,
    (newV, oldV) => {
      if (newV.length > oldV.length) {
        const index = chatMessages.value.length - 1;
        const lastMessage = chatMessages.value[index];
        if (lastMessage && lastMessage?.fromUserId !== gameStore?.currentTeam.id) {
          hasNewMessage.value = true;
        }
      }
    },
    { deep: true }
  );

  return {
    chatMessages,
    hasNewMessage,
    getChatMessages,
  };
});
