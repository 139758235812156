<template>
  <div
    v-if="appConfig.enableCheckin === true"
    id="notify-target"
    class="notify notify--target is-expanded"
  >
    <div
      id="dep-1"
      class="notify__department"
      :class="expanded ? 'is-expanded' : ''"
    >
      <div
        class="notify__header notify__header--alt"
        @click="$emit('close')"
      >
        <p class="text-faded">
          {{ $t("components.CardCheckin.near_text") }}
        </p>
        <h3 class="notify__title notify__title--large">
          {{ poiTitle }}
        </h3>
        <p class="text-faded" />
        <!-- /.text-faded -->
      </div>

      <!-- /.notify__header -->
      <ul class="notify__offers">
        <li>
          <cloud-image
            class="border5"
            :base-width="151"
            :ratio="1.42"
            :use-pixel-density="true"
            :picture-path="pictures[0]"
          />
        </li>
        <li v-if="platformType === 'urbanrace'">
          <div class="offer">
            <span class="offer__price">
              <img
                src="../../assets/images/icons/picto-dotmap-rouge-15.svg"
                width="64"
                height="64"
                alt="GPS pin logo"
              >
            </span>
          </div>
        </li>
        <li v-else>
          <div class="offer">
            <span class="offer__price">
              {{ poi.points }}
              <sup>PTS</sup>
            </span>
            <span class="offer__subtitle">{{ $t("components.CardCheckin.offer") }}</span>
            <a
              href="#"
              class="btn-round btn-round--small btn-round--secondary offer__btn"
            >
              <img
                src="../../assets/images/icons/ico-gift-plain.svg"
                width="16"
                height="15"
                alt="gift icon"
              >
            </a>
          </div>
        </li>
      </ul>
      <!-- /.notify__offers -->
    </div>
    <QrcodeStream
      v-if="isScanning === true"
      :track="paintBoundingBox"
      @decode="onRead"
    />
    <div class="notify__footer">
      <panel-poi-btn
        :poi="poi"
        :survey-count="0"
        :loading="loading"
        @clicked="onButtonClicked"
      />
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import PanelPoiBtn from './PanelPOIBtn.vue';
import { useConfigStore } from '../../pinia/configuration';
import  {usePOIManager} from '../../composables/POIManager';
import { UseCheckinControl } from '../../composables/CheckinControl';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { QrcodeStream } from 'vue3-qrcode-reader'

const props = defineProps({
  expanded: {
    type: Boolean,
    required: true,
  },
  poi: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'checkinstart', 'checkindone', 'checkincancelled']);
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const configStore = useConfigStore();
const { poiTitle, pictures } = usePOIManager(props.poi);
const { performCheckin } = UseCheckinControl(props.poi);


const isScanning = ref(false);
const loading = ref(false);

const appConfig = computed(() => configStore.appConfig);
const platformType = computed(() => appConfig.value.platformType);

const onButtonClicked = async(event) => {
  switch (event.action) {
    case 'closeCard':
      pushRoute('home');
      break;
    case 'login':
      pushRoute('Login');
      break;
    case 'register':
      pushRoute('Register');
      break;
    case 'checkin':
      emit('checkinstart');
      const res = await performCheckin(props.poi);
      if (res) {
        emit('checkindone', { poiId: props.poi.uuid, score: props.poi.points });
      } else {
        emit('checkincancelled');
      }
      break;
    case 'scan':
      isScanning.value = true;
      break;
    default:
      break;
  }
};

const pushRoute = (routeName) => {
  if (routeName !== route.name) {
    router.push({ name: routeName });
  }
};

const onRead = async(qrCode) => {
  if (props.poi.uuid === qrCode.trim()) {
    emit('checkinstart');
    const res = await performCheckin(props.poi);
    if (res) {
      emit('checkindone', { poiId: props.poi.uuid, score: props.poi.points });
    } else {
      emit('checkincancelled');
    }
    isScanning.value = false;
  } else {
    isScanning.value = false;
    Swal.fire({
      title: `${t('components.PanelContainer.error.wrongQR')}`,
      icon: 'error',
    });
  }
};

const paintBoundingBox = (detectedCodes, ctx) => {
  if (!detectedCodes || !Array.isArray(detectedCodes)) {
    return;
  }
  
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: {
        x, y, width, height,
      },
    } = detectedCode;
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#DA004B';
    ctx.strokeRect(x, y, width, height);
  }
}
</script>
